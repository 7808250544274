import { MouseEvent } from "react";

import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList
} from "@chakra-ui/react";

import NavItem from "../NavItem/NavItem.tsx";

interface Props {
    handleLogout: (event: MouseEvent<HTMLButtonElement>) => void;
}

const MobileNav = ({ handleLogout }: Props) => {
    return (
        <Menu>
            <MenuButton cursor="pointer">
                <FontAwesomeIcon
                    icon={faBars}
                    color="var(--euka-colors-ebonyClay-500)"
                    fontSize={24}
                    data-testid="barImg"
                />
            </MenuButton>
            <MenuList>
                <MenuItem as="a" href={route("parents.dashboard")}>
                    <NavItem url={route("parents.dashboard")} linkText="Home" />
                </MenuItem>
                <MenuItem as="a" href={route("parents.manage-programs")}>
                    <NavItem
                        url={route("parents.manage-programs")}
                        linkText="Manage programs"
                    />
                </MenuItem>
                <MenuItem as="a" href={route("parents.student-documents")}>
                    <NavItem
                        url={route("parents.student-documents")}
                        linkText="Student documents"
                    />
                </MenuItem>
                <MenuItem as="a" href={route("external.ecom.resources")}>
                    <NavItem
                        url={route("external.ecom.resources")}
                        linkText="Resource centre"
                        isExternal
                    />
                </MenuItem>
                <MenuItem as="a" href={route("external.ecom.help")}>
                    <NavItem
                        url={route("external.ecom.help")}
                        linkText="Help"
                        isExternal
                    />
                </MenuItem>
                <MenuItem as="a" href={route("parents.account-details")}>
                    <NavItem
                        url={route("parents.account-details")}
                        linkText="Account details"
                    />
                </MenuItem>
                <MenuItem as="a" href={route("parents.shared-access")}>
                    <NavItem
                        url={route("parents.shared-access")}
                        linkText="Shared access"
                    />
                </MenuItem>
                <MenuDivider marginInline={4} />
                <MenuItem
                    as="a"
                    href={route("external.lms.home")}
                    target="_blank"
                    padding="0.5rem 1rem"
                >
                    Switch to student portal
                </MenuItem>
                <MenuItem padding="0.5rem 1rem" onClick={handleLogout}>
                    Logout
                </MenuItem>
            </MenuList>
        </Menu>
    );
};

export default MobileNav;
