import { usePage } from "@inertiajs/react";

interface SiteLinks {
    termsConditionsUrl: string;
    literacyPlanetLoginUrl: string;
    mathleticsLoginUrl: string;
}

const useSettingsPageProps = () => {
    const props = usePage().props as unknown as {
        isUserNotificationEnabled: boolean;
        recaptchaSiteKey: string;
        siteLinks: SiteLinks;
        isEnableCoolingOffPeriod: boolean;
        isLearnosityAssessmentsEnabled: boolean;
        eukaEmailAddress: string;
        versionText: string;
        appVersion: string;
        parentsNav: {
            id: number;
            route: string;
            name: string;
            isExternal: boolean;
        }[];
    };

    return props;
};

export default useSettingsPageProps;
